import {
    IssueStatus, MaterialType, Severity, type CommentType, Issue
} from '../../shared/shared.interface';
import { User } from '../types/user';
const INIT_LAT_LNG = 0;
const currentIssueObj: Issue = {
    address: '',
    severity: Severity.Low,
    issueImages: [],
    location: {
        coordinates: [ INIT_LAT_LNG, INIT_LAT_LNG ]
    },
    mapURL: '',
    dateTaken: new Date(),
    issueDescription: '',
    issueType: '',
    fixTime: 0,
    issueStatus: IssueStatus.PENDING,
    userName: '',
    reporter: '',
    assignee: '',
    materialType: MaterialType.A01,
    quantityUsed: 0,
    diameter: 0,
    depth: 0
};

export const useCurrentIssue = () => useState<Issue>(
    'currentIssue',
    () => currentIssueObj
);

export const useIssues = () => useState<Issue[]>(
    'issues',
    () => []
);
export const useComments = () => useState<CommentType[]>(
    'comments',
    () => []
);
export const useIsEditIssue = () => useState<boolean>(
    'isEditissue',
    () => false
);
export const useUser = () => useState<User>(
    'user',
    () => ({
        name: '',
        email: '',
        avatar: '',
        sub: '',
        role_id: '',
        permissions: []
    })
);
